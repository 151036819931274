<template>
  <div class="feedback"> 
    <section class="tealBG">
      <b-container class="card contact-card">
        <!-- <p class="frm-lbl-top">*denotes mandatory field</p> -->
        <NameAddress :title="data.formName" :text="data.formDescription" :nameAddress="nameAddressDataSorted"/>
        <RadioColumnSingle :questions="data.selectionBox[1]" v-if="data.selectionBox"/>
        <OptionComments :options="data.comment" :title="false"/>
        <DataProtection :text="data.dataProtectiontextComponent" />
        <RadioColumn :questions="data.selectionBox[0]"  v-if="data.selectionBox"/>
        <div class="fl pt-3"><button class="btn" @click="collectInputs"  v-if="language.name === 'english'">Submit</button></div>
         <div class="fl pt-3"><button class="btn" @click="collectInputs"  v-if="language.name === 'welsh'">Cyflwyno</button></div>
      </b-container>
    </section>
  </div>
</template>

<script>
import NameAddress from "../FormComps/NameAddress";
import OptionComments from "../FormComps/OptionComments";
import DataProtection from "../FormComps/DataProtection";
import FeedbackService from "../../services/FeedbackServiceTFG";
import RadioColumn from "../FormComps/RadioColumn";
import RadioColumnSingle from "../FormComps/RadioColumnSingle";
import { mapGetters } from "vuex";
const _ = require('lodash');
export default {
  name: "QuestionnaireJFTG",
  components: {
      RadioColumnSingle,
    RadioColumn,
    NameAddress,
    OptionComments,
    DataProtection,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      rawFeedbackData: [],
    };
  },
  computed: {
     ...mapGetters(["language"]),
    nameAddressDataSorted(){
      //console.log('DATA FORM',this.data);
      if(this.data){
           return _.orderBy(this.data.nameAddress, 'orderNumber', 'asc');
      }else{ 
        return this.data[0].nameAddress
      }
    }
  },
  methods: {
    collectInputs() {
              this.$gtag.event('SubmitJTFGForm', {
                      'event_category': 'Submit',
                      'event_label': 'Form',
                      'value': 'JoinTaskGroup'
                    })
      let inputDataArr = [];
      for (let i = 0; i < this.$children.length; i++) {
        inputDataArr.push(this.$children[i]._data);
      }
      
      let service = new FeedbackService(inputDataArr);
      service.checkInputsArePresent();
    },
  },
};
</script>

<style lang="scss">
.contact-card{
        box-shadow: 0px 0px 23px 3px rgba(49, 156, 140, 0.16);
        border-radius: 8px;
        background: #fff;
        border: none;
        margin-bottom: 150px;
        padding: 40px;
  }
</style>