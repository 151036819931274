  <template>
    <main class="home">
   
      <!-- HEADER TOP MENU BAR -->
        <Header></Header>
      <!-- <Header :currentLanguage="currentLanguage"  :navbar="bannerData.navs"></Header> -->
      <!-- Rest of the HOME PAGE STARTS -->
        <div class="LandingPage">
          <!-- SECTION 1 -->  
          <section  v-for="(data,index) in homePageContentData" :key="index" :class="data.templateType.slug+'BackImage'" > 
            <b-container>
              <Sections :sectionData="data" :sectionType="data.templateType.slug" />
            </b-container>
          </section>
          </div>
           <Questionnaire :data="populateFormData" />
      <!-- footer section -->
            <section class="footer">
              <footer>
                <cookie-law theme="dark-lime"></cookie-law>
              </footer>
              <Footer/>
            </section>
    </main>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sections from "../components/Section";

import {FlamelinkPlugin} from "@/services/FlamelinkSettings";

import Questionnaire from "../components/Project/QuestionnaireJFTG";
const _ = require('lodash');
import { mapGetters } from "vuex";

export default {
  name: "JoinTaskFinishGroup",

  data(){
    return{
      dataReady:false,
      mydata:[],
     
    }
  },
  components: {
    Sections,
    Questionnaire,
    Header,
    Footer,
  },
  //  async mounted() {
  //    await FlamelinkPlugin.content.get({ schemaKey: 'contactForm' })
  //    .then( (response)=> {
  //       //console.log('RRR',response);
  //         Object.values(response).forEach(val => {
  //           this.mydata.push(val)
  //         });
  // console.log('RRR', this.mydata);
  //       //this.mydata = response;
  //       this.dataReady = true  ;
  //     })
  //     .catch( (error)=> {
  //       console.log(error);
  //     })
  // },
  method: {
    
  },
  computed: {
    ...mapGetters(["language", "content"]),
    populateFormData(){
      if (this.content && this.content.cForm) {
        return this.content.cForm[1]
        } else return {};
    },
    homePageContentData() {
        var route=this.$route.path.split('/');
        var newArr=[]; 
        if (this.content && this.content.pageContent) {
            newArr= this.content.pageContent.filter((el)=> {
              if(el.linkedPage.length>0){
                  return el.linkedPage[0].pageSlug == route[1] ;
              }
                
            });
        return _.orderBy(newArr, 'orderNumber', 'asc');
        } else return {};
    },
   
  
   
    currentLanguage() {
      if (this.language) {
        return this.language;
      } else return "english";
    },
  },
 
  async mounted() {
    this.scrollToTop()
    await this.$gtag.query('event', 'screen_view', {
        app_name: 'MyApp',
        screen_name: 'Join Task Finish Group',
      })
  },

 methods: { 

      scrollToTop() {
          window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
 
      }
  },
};
</script>
